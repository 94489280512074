.benefit {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.benefit > div:first-child {
  height: 180px;
}
.benefit p,
.benefit h3 {
  text-align: center;
  margin-top: 0;
}
.benefit h3 {
  font-weight: 400;
  color: var(--secondary-dark-text);
  margin-bottom: 0.5em;
}
.benefit p {
  color: var(--primary-dark-text);
  max-width: 80%;
  margin: 0 auto 1.5em;
}
.benefit img {
  max-width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
  margin: auto;
  position: relative;
}
.benefit .img-container {
  position: relative;
}
.benefits .benefit .img-container::before {
  content: '';
  position: absolute;
  background-color: var(--primary-color);
  border-radius: 50%;
  top: 0;
  left: 0;
}
.benefits .benefit:nth-child(3n+1) .img-container::before {
  width: 110px;
  height: 110px;
}
.benefits .benefit:nth-child(3n+2) .img-container::before {
  width: 110px;
  height: 110px;
  bottom: 4px;
  top: unset;
}
.benefits .benefit:nth-child(3n+3) .img-container::before {
  width: 90px;
  height: 90px;
  bottom: unset;
  top: 0;
  left: unset;
  right: 0
}
.benefits .benefit:nth-child(3n+4) .img-container::before {
  width: 90px;
  height: 90px;
  bottom: 4px;
  top: unset;
  left: unset;
  right: 0
}
