@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  box-sizing: border-box;
  font-family: 'Roboto Condensed', sans-serif !important;
  overflow-x: hidden !important;
}
body *,
body * > * {
  box-sizing: border-box !important;
  font-family: 'Roboto Condensed', sans-serif !important;
}

:root {
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;

  --primary-color: #18be91;
  --primary-background: radial-gradient(
    75.56% 191.34% at 5.6% 5.41%,
    #404040 0%,
    #282828 100%
  );
  --primary-dark-text: rgb(64, 64, 64);
  --secondary-dark-text: rgba(18, 18, 18, 1);
  --primary-light-text: #fff;
  --secondary-light-text: rgba(179, 179, 179, 1);
}
a{
  color: #18be91;
  color: var(--primary-color)
}
.input {
  font-size: 0.9em;
}
.primary-color {
  color: #18be91;
  color: var(--primary-color);
}
.secondary-light-color {
  color: rgba(179, 179, 179, 1);
  color: var(--secondary-light-text);
}
.secondary-dark-color {
  color: rgba(18, 18, 18, 1);
  color: var(--secondary-dark-text);
}
.primary-light-color {
  color: #fff;
  color: var(--primary-light-text);
}
.primary-dark-color {
  color: rgb(64, 64, 64);
  color: var(--primary-dark-text);
}
.general-body {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  display: -webkit-flex;
  display: flex;
}
.general-body > * {
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.general-shadow {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}
.center-text {
  text-align: center;
}

.weight-bold {
  font-weight: 600;
}
.weight-normal {
  font-weight: 400;
}
.font-size-sm {
  font-size: 0.9em;
}

.mt-1 {
  margin-top: 0.25em;
}
.mt-2 {
  margin-top: 0.5em;
}
.mt-3 {
  margin-top: 0.75em;
}
.mt-4 {
  margin-top: 1em;
}
.mt-5 {
  margin-top: 1.25em;
}
.mt-6 {
  margin-top: 1.5em;
}
.mt-7 {
  margin-top: 1.75em;
}
.mt-8 {
  margin-top: 2em;
}
.pointer {
  cursor: pointer;
}
.inline-block {
  display: inline-block;
}
@media (min-width: 750px) {
  :root {
    font-size: 18px;
  }
  .general-body {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
}

input {
    width: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  input.outlined {
    border: 1px solid;
    border-radius: 5px;
  }
  input {
    border: none;
    /* padding: 20px 62px 20px 25px; */
    border-radius: 5px;
    outline: none;
    font-size: 0.9rem;
  }
  
#button {
  border: none;
  padding: 0.5em 2em;
  position: relative;
  cursor: pointer;
  font-size: 0.9rem
}
#button:hover::after{
    content: '';
    position:absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    background: linear-gradient(35deg,rgba(255,255,255,0.3), rgba(255,255,255,0));
}
#button .children {
  white-space: nowrap !important;
}
#button.loading .loader {
  display: block;
}
#button.loading .children {
  opacity: 0;
}

#button.disabled {
  background-color: rgba(199, 199, 199, 0.47) !important;
  color: rgba(79, 78, 78, 0.78) !important;
  cursor: default;
  box-shadow: none !important
}

#button .loader {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 50%;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid white;
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  display: none;
  -webkit-animation: rotate 0.6s linear infinite;
          animation: rotate 0.6s linear infinite;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#action-button {
  border: none;
  border-radius: 5px;
  padding: 4px 6px;
}

#homepage .page-footer {
  background: linear-gradient(111.27deg, #404040 4.89%, #282828 75.95%);
  position: relative;
  overflow: hidden;
  padding-top: 1em;
  padding-bottom: 2.5em;
}
#homepage .page-footer > div > .content {
  max-width: 500px;
  margin: 0 auto;
}
#homepage .page-footer .logo {
  display: none;
}
#homepage .page-footer > div > img {
  display: none;
}
#homepage .page-footer .content p {
  text-align: center;
  margin-bottom: 2em;
}
#homepage .page-footer .social-links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
#homepage .page-footer .social-links > * {
  margin: 0 0.5em !important;
}
#homepage .page-footer .copyright-social {
  margin-top: 2em;
}
#homepage .page-footer .copyright {
  margin: 1em 0;
  font-size: 0.9em;
}
#homepage .page-footer .textfield-button > .button {
  margin-top: 1.75em;
}
#homepage .page-footer .privacy-terms{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 1.5em;
}

@media (min-width: 750px) {
  #homepage .page-footer {
    background: radial-gradient(
      100% 255.17% at 0% 22.13%,
      #282828 0%,
      #404040 58.79%,
      rgba(64, 64, 64, 0.6) 62.97%,
      rgba(64, 64, 64, 0.4) 67.11%,
      rgba(64, 64, 64, 0.2) 71.13%,
      rgba(64, 64, 64, 0) 75.31%
    );
    position: relative;
    overflow: hidden;
    padding-top: 1.5em;
    padding-bottom: 3em;
  }
  #homepage .page-footer > div > .content {
    margin: 0;
  }
  #homepage .page-footer > div > img {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    /* position: relative; */
    width: 50%;
    z-index: -1;
    height: 100%;
    object-fit: cover;
  }
  #homepage .page-footer > div > .content p {
    text-align: start;
  }
  #homepage .page-footer > div > .content .headline {
    text-align: start;
  }
  #homepage .page-footer > div > .content .textfield-button {
    display: -webkit-flex;
    display: flex;
  }
  #homepage .page-footer > div > .content .textfield-button .button {
    margin-top: 0;
    margin-left: 1em;
    width: auto !important;
  }
  #homepage .page-footer .textfield-button > .textfield {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }
  #homepage .page-footer .copyright-social {
    display: -webkit-flex;
    display: flex;
    margin-top: 3em;
  }
  #homepage .page-footer .copyright-social > .social-links {
    margin-left: 1em;
  }
  #homepage .page-footer .logo {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: var(--primary-light-text);
  }
  #homepage .page-footer .logo > h1 {
    margin-left: 0.3em;
  }
  #homepage .page-footer .privacy-terms{
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
}

#toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}
#toolbar .logo > h1 {
  display: none;
}
#toolbar .logo {
  color: var(--primary-light-text);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
#toolbar .logo.dark {
  color: #000;
}
#toolbar > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
#toolbar > div > *:not(:first-child) {
  margin-left: 1rem;
}

@media (min-width: 750px) {
  #toolbar .logo > h1 {
    display: block;
  }
}

.offer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.offer > p,
.offer > h4 {
  text-align: center;
}
.offer > h3 {
  font-weight: 400;
  color: var(--secondary-dark-text);
}
.offer > p {
  color: var(--primary-dark-text);
  max-width: 80%;
  margin: 0 auto 1.5em;
}
.offer:first-child > img {
  max-width: 160px;
}
.offer > img {
  max-width: 120px;
  margin-top: auto;
}

.benefit {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.benefit > div:first-child {
  height: 180px;
}
.benefit p,
.benefit h3 {
  text-align: center;
  margin-top: 0;
}
.benefit h3 {
  font-weight: 400;
  color: var(--secondary-dark-text);
  margin-bottom: 0.5em;
}
.benefit p {
  color: var(--primary-dark-text);
  max-width: 80%;
  margin: 0 auto 1.5em;
}
.benefit img {
  max-width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
  margin: auto;
  position: relative;
}
.benefit .img-container {
  position: relative;
}
.benefits .benefit .img-container::before {
  content: '';
  position: absolute;
  background-color: var(--primary-color);
  border-radius: 50%;
  top: 0;
  left: 0;
}
.benefits .benefit:nth-child(3n+1) .img-container::before {
  width: 110px;
  height: 110px;
}
.benefits .benefit:nth-child(3n+2) .img-container::before {
  width: 110px;
  height: 110px;
  bottom: 4px;
  top: unset;
}
.benefits .benefit:nth-child(3n+3) .img-container::before {
  width: 90px;
  height: 90px;
  bottom: unset;
  top: 0;
  left: unset;
  right: 0
}
.benefits .benefit:nth-child(3n+4) .img-container::before {
  width: 90px;
  height: 90px;
  bottom: 4px;
  top: unset;
  left: unset;
  right: 0
}

.target {
  background: var(--primary-background);
  padding-top: 1.5em;
  /* padding-bottom: 2em */
  max-width: 1000px;
  margin: 2em auto 0;
}
.target .content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.target .content .title,
.target .content .info {
  text-align: center;
}
.targets .target:nth-child(3) .content img{
  width: 100%;
  max-width: 289px;
}
.target img {
  margin-top: 2.5em;
  max-width: 100%;
  /* margin-bottom: -30px; */
}
.target .info {
  color: var(--secondary-light-text);
}
.target .title {
  color: var(--primary-light-text);
  /* font-size: 3.33em; */
  font-weight: normal;
}

@media (min-width: 750px) {
  .target {
    overflow: hidden;
  }
  .target .content {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: start;
            align-items: start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .target .content.even {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-align-items: start;
            align-items: start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .target .content .title,
  .target .content .info {
    text-align: start;
  }
  .target .content .info {
    max-width: 35em;
    margin-bottom: 1.5em;
  }
  .target .content img {
    /* margin-top: -3.8em; */
    margin-top: -9%;
    margin-right: -7.3em;
    margin-left: 2em;
    width: 30em;
  }
  .target .content.even img {
    margin-top: -2em;
    margin-left: -4em;
    margin-right: 2em;
    width: 30em;
  }
  .targets .target:nth-child(3) .content img{
    margin-top: -6em;
    margin-left: 2em;
    margin-right: -9em;
    max-width: 22em;
  }
}
@media (min-width: 800px){
  .targets .target:nth-child(3) .content img{
    margin-top: -6em;
    margin-left: 2em;
    margin-right: -9em;
    max-width: 25em;
  }
}
@media (min-width: 1000px) {
  .targets .target:nth-child(3) .content img{
    margin-top: -6em;
    margin-left: 2em;
    margin-right: -9em;
    max-width: 27em;
  }
  .target {
    border-radius: 10px;
    margin: 4em auto 0;
  }
}

.card {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  /* gap: 1em; */
}
.card.even{
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.card > div{
  display: -webkit-inline-flex;
  display: inline-flex;
  /* background-color: teal; */
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.card > div > div{
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: auto
}
.card.even > div > div{
  margin-right: auto;
  margin-left: 0;
}
.card p,
.card h3 {
  text-align: center;
  margin-top: -0.4em;
}
.card h3 {
  font-weight: 400;
  color: var(--secondary-dark-text);
}
.card p {
  color: var(--primary-dark-text);
  max-width: 80%;
  margin: -0.4em auto 0;
}
.card > img {
  max-width: 100px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(61, 48, 48, 0.2);
}
@media (min-width:750px) {
  .card {
    padding-right: 1em;
  }
  .card.even{
    padding-left: 1em;
  }
  .card > img {
    max-width: 150px;
    margin: auto;
  }
}

#approval-dialog {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: -webkit-flex;
  display: flex;
  z-index: -20;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100% + 2px);
}
#approval-dialog>* {
  overflow-y: auto !important;
}
#approval-dialog.show {
  z-index: 20;
  visibility: visible;
}
#approval-dialog > #container {
  margin: auto;
  width: 100%;
  max-width: 850px;
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden !important;
  background-color: white;
  height: 100%;
  position: relative;
}
#approval-dialog .contents {
  /* min-height: 100vh; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  height: 100%;
  overflow-y: auto;
}
#approval-dialog.show > #container {
  -webkit-transform: scale(1);
          transform: scale(1);
}
#approval-dialog .forms {
  /* overflow-y: auto; */
  padding-top: 0 !important;
}
#approval-dialog .forms > div:not(:first-child) {
  max-width: 500px;
  margin: 0 auto;
}
#approval-dialog .forms > .close {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-top: 1em;
  padding-bottom: 1em;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
}
#approval-dialog .forms .buttons > *:first-child {
  margin-bottom: 1em;
}
#approval-dialog .mobile-nav > div {
  max-width: 500px;
  margin: 0 auto;
}
#approval-dialog .forms > .close > img {
  width: 16px;
}
#approval-dialog .contents > div {
  padding: 1.5em;
}
#approval-dialog .contents > div:not(:first-child) {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding-bottom: 2em;
}
#approval-dialog .contents > div:first-child {
  background: var(--primary-background);
}
#approval-dialog .tab-nav {
  display: none;
}
#approval-dialog .congrats > .logo > img {
  width: 1.8em;
}
#approval-dialog .congrats > .logo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}
#approval-dialog .congrats {
  -webkit-animation: bounce-in 0.2s cubic-bezier(0.17, 0.67, 0.94, 1.21);
          animation: bounce-in 0.2s cubic-bezier(0.17, 0.67, 0.94, 1.21);
}
/* #approval-dialog .forms>div:not(:first-child) {
  animation: slide-left 0.2s ease;
} */
#approval-dialog .step-3 {
  -webkit-animation: slide-left 0.2s ease;
          animation: slide-left 0.2s ease;
}
#approval-dialog .step-2.is-forward {
  -webkit-animation: slide-middle-left 0.2s ease;
          animation: slide-middle-left 0.2s ease;
}
#approval-dialog .step-2.is-backward {
  -webkit-animation: slide-middle-right 0.2s ease;
          animation: slide-middle-right 0.2s ease;
}
#approval-dialog .step-1 {
  -webkit-animation: slide-right 0.2s ease;
          animation: slide-right 0.2s ease;
}

#approval-dialog .url-body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 50px;
}

#approval-dialog .url-body > input {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 25px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid lightgray;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none !important;
}

#approval-dialog .url-body > button {
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #393939;
  padding: 0 20px;
  cursor: pointer;
  color: white;
}

#approval-dialog .referral-body {
  -webkit-animation: bounce-out 0.2s cubic-bezier(0.17, 0.67, 0.94, 1.21);
          animation: bounce-out 0.2s cubic-bezier(0.17, 0.67, 0.94, 1.21);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  overflow-y: auto;
  position: relative;
}
#approval-dialog .referral-body .ref-close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  max-width: 24px;
}
#approval-dialog .referral-body .links {
  padding: 30px;
  text-align: center;
}
#approval-dialog .referral-body .links > p {
  color: var(--primary-dark-text);
}
#approval-dialog .referral-body .links > h3 {
  margin-bottom: 0;
}
#approval-dialog .referral-body .rewards-container {
  background: radial-gradient(
    103.71% 76.48% at 50% 102.09%,
    #5c5c5c 0%,
    #282828 100%
  );
  padding: 30px;
  padding-top: 0;
  color: white;
}

#approval-dialog .rewards .reward {
  font-size: 0.9em;
  overflow-y: hidden;
  position: relative;
}
#approval-dialog .rewards .reward > .sm {
  padding-top: 25px;
  display: -webkit-flex;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  -webkit-align-items: center;
          align-items: center;
}
#approval-dialog .rewards .reward > .sm > .info {
  margin: 0;
  max-width: 130px
}
#approval-dialog .rewards .reward > .bg {
  display: none;
}
#approval-dialog .rewards .reward .ref-count {
  text-align: center;
  color: black;
  background-color: white;
  border-radius: 50%;
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
#approval-dialog .rewards .reward .ref-count > div {
  font-weight: bold;
  margin-top: 3px;
}
#approval-dialog .rewards .reward > .sm::after {
  content: '';
  width: 3px;
  position: absolute;
  left: 18.5px;
  background-color: white;
  bottom: 0;
  height: 100%;
}
#approval-dialog .rewards .reward:last-child > .sm::after {
  bottom: 50%;
}
#approval-dialog .rewards .reward .ref-count > span {
  font-size: 10px;
  margin-top: -3px;
  display: block;
}
#approval-dialog .rewards .reward img {
  max-width: 90px;
}
#approval-dialog .referral-body .girl-img {
  display: none;
}
.copy-url-parent {
  display: -webkit-flex;
  display: flex;
  margin-top: 1em;
}
.copy-url-parent > div {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}
.copy-url {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

@media (min-width: 750px) {
  #approval-dialog .forms > .close > img {
    position: relative;
    top: 0;
    right: 0;
  }
  #approval-dialog {
    padding: 2em;
    -webkit-backdrop-filter: blur(90px);
            backdrop-filter: blur(90px);
  }
  #approval-dialog > #container {
    overflow-y: hidden;
    max-height: 90%;
    border-radius: 10px;
  }
  #approval-dialog > #container.registered {
    overflow-y: auto;
  }
  #approval-dialog .contents {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  #approval-dialog .mobile-nav {
    display: none;
  }
  #approval-dialog .tab-nav {
    display: block;
  }
  #approval-dialog .contents > div {
    padding: 2.2em;
  }
  #approval-dialog .contents > .navigation {
    padding: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  #approval-dialog .contents > .forms {
    padding-top: 1em;
    overflow-x: hidden !important;
  }
  #approval-dialog .contents > .navigation > .tab-nav .info {
    padding: 1em 1.5em;
  }
  #approval-dialog
    .contents
    > .navigation
    > .tab-nav
    .info
    > p:not(:first-child) {
    margin: 2em 0;
  }
  #approval-dialog .forms .buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    margin-top: 2em;
    grid-gap: 1.5em;
    gap: 1.5em;
    -webkit-justify-content: center;
            justify-content: center;
  }
  #approval-dialog .referral-body .girl-img {
    display: block;
    max-width: 200px;
    position: absolute;
    top: 0;
    right: 0;
  }
  #approval-dialog .forms .buttons > *:first-child {
    margin-bottom: 0;
  }
  #approval-dialog .rewards .reward > .sm {
    display: none;
  }
  #approval-dialog .rewards .reward > .bg {
    display: block;
    /* padding-left: 40px; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    overflow: hidden;
  }
  #approval-dialog .referral-body .rewards-container {
    padding-top: 30px;
    padding-left: 0;
    background: radial-gradient(
      73.99% 173.2% at 87.14% 15.59%,
      #5c5c5c 0%,
      #282828 100%
    );
  }
  #approval-dialog .rewards {
    display: -webkit-flex;
    display: flex;
  }
  #approval-dialog .rewards .reward {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }
  #approval-dialog .rewards .reward > .bg > .img-container {
    height: 100px;
    width: 100px;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 20px;
  }
  #approval-dialog .rewards .reward > .bg > .img-container > img {
    width: 100px;
    max-width: 100%;
    margin: auto;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  #approval-dialog .rewards .reward > .bg > .ref-count {
    margin-bottom: 20px;
    position: relative;
    overflow-x: visible;
  }
  #approval-dialog .rewards .reward > .bg:after {
    content: '';
    height: 3px;
    background-color: white;
    position: absolute;
    left: 0;
    top: 139px;
    width: 100%;
  }
  #approval-dialog .rewards .reward:last-child > .bg:after {
    width: 50%;
  }
  #approval-dialog .referral-body .links {
    /* padding-bottom: 0px; */
  }
  #approval-dialog .rewards .reward > .bg > .info {
    text-align: center;
    max-width: 150px;
  }
  #approval-dialog .referral-body .links {
    text-align: start;
  }
  #approval-dialog .referral-body .links > h3 {
    font-size: 200%;
    margin-bottom: 0;
  }
  #approval-dialog .referral-body .links > p {
    max-width: 450px;
  }
  .copy-url {
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
  }
  .copy-url-parent > div {
    max-width: 100%;
  }
}

@-webkit-keyframes bounce-in {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes bounce-in {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes bounce-out {
  0% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes slide-middle-left {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-middle-left {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes slide-middle-right {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-middle-right {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.social-icon + .social-icon {
  margin-left: 0.8em;
  margin-top: 0.8em;
}

/* loader */

.loader {
  text-align: center;
}
.loader span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin: 50px auto;
  background: black;
  border-radius: 50px;
  -webkit-animation: loader 0.9s infinite alternate;
  -moz-animation: loader 0.9s infinite alternate;
}
.loader span:nth-of-type(2) {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
}
.loader span:nth-of-type(3) {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
}
@-webkit-keyframes loader {
  0% {
    width: 10px;
    height: 10px;
    opacity: 0.9;
    -webkit-transform: translateY(0);
  }
  100% {
    width: 24px;
    height: 24px;
    opacity: 0.1;
    -webkit-transform: translateY(-21px);
  }
}

#snackbar {
  position: fixed;
  height: auto;
  width: 100%;
  overflow: visible;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 2em;
  top: 0;
  z-index: -1;
  opacity: 1;
  transition: none 1s ease;
  transition-property: z-index,opacity,visibility,-webkit-transform;
  transition-property: z-index,opacity,transform,visibility;
  transition-property: z-index,opacity,transform,visibility,-webkit-transform;
  padding: 0 1em;
}
#snackbar.show {
  z-index: 800;
  opacity: 1;
}
#snackbar > div {
  max-width: 400px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  color: white;
  visibility: hidden;
  transition: none 1s ease;
  transition-property: z-index,opacity,visibility,-webkit-transform;
  transition-property: z-index,opacity,transform,visibility;
  transition-property: z-index,opacity,transform,visibility,-webkit-transform;
  -webkit-transform: translateY(-7em);
          transform: translateY(-7em);
  padding: 1em 2em;
  border-radius: 4px;
}
#snackbar.error > div{
  background-color: rgba(250, 120, 121, 1);
}
#snackbar.success > div{
  background-color: rgba(38, 170, 118, 1);
}
#snackbar.info > div{
  background-color: #5294F7;
}
#snackbar.show > div {
  visibility: visible;
  -webkit-transform: translateY(2em);
          transform: translateY(2em);
}

#homepage .page-header {
  /* min-height: 100vh; */
  /* width: 100%; */
  background: var(--primary-background);
  padding: 1rem 0;
  overflow: hidden;
}

#homepage .page-header .headline {
  color: var(--primary-light-text);
  text-align: center;
  line-height: 3.13rem;
  font-size: 2.66rem;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 3.5rem;
}
#homepage .page-header .headline > span {
  color: var(--primary-color);
}

#homepage .page-header .about {
  color: var(--secondary-light-text);
  text-align: center;
  max-width: 300px;
  margin: 2rem auto;
}
#homepage .page-header .header-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  max-width: 500px;
  margin: 0 auto;
}
#homepage .page-header .header-content p {
  text-align: center;
}
#homepage .page-header .header-content img {
  width: 200px;
  max-width: 50%;
  margin-top: 2.5em;
  box-shadow: 2em 1em 3em rgba(0, 0, 0, 0.8), 4em 2em 5em rgba(0, 0, 0, 0.6),
    6em 3em 3em rgba(0, 0, 0, 0.4), 8em 4em 3em rgba(0, 0, 0, 0.3);
  border-radius: 2em;
  margin-bottom: -70px;
}
#homepage .page-header .textfield-button > .button {
  margin-top: 1.75em;
}

@media (min-width: 750px) {
  #homepage .page-header {
    padding-bottom: 5.5em;
  }
  #homepage .page-header .header-content {
    margin-top: 8em;
    max-width: inherit;
  }
  #homepage .page-header .header-content > div {
    max-width: 35em;
  }
  #homepage .page-header .header-content {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: start;
            align-items: start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  #homepage .page-header .headline .word-break {
    display: none;
  }
  #homepage .page-header .header-content img {
    margin-right: 1em;
    margin-left: 2em;
    margin-bottom: 0;
  }
  #homepage .page-header .headline {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }
  #homepage .page-header .about {
    text-align: left;
    max-width: 80%;
    margin: 2rem 0;
  }
  #homepage .page-header .header-content p {
    text-align: left;
  }

  #homepage .page-header .textfield-button {
    display: -webkit-flex;
    display: flex;
  }
  #homepage .page-header .textfield-button > .button {
    margin-top: 0;
    margin-left: 1em;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    width: auto !important;
  }
  #homepage .page-header .textfield-button > .textfield {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }
  #homepage .page-header .toolbar > .logo > h1 {
    display: block;
  }
}
@media (min-width: 960px) {
  #homepage .page-header .header-content img {
    margin-right: 5em;
    margin-left: 2em;
    margin-bottom: 0;
  }
}

#hompage {
  overflow-x: hidden !important;
}
#homepage .headline {
  text-align: center;
}
/* #homepage .page-body h1,
#homepage .page-body p {
  text-align: center;
} */
#homepage .page-body .method-text {
  color: var(--primary-dark-text);
  max-width: 700px;
  margin: auto;
}
#homepage .page-body .grid {
  margin-top: 3em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 3em;
  gap: 3em;
}
#homepage .page-body .grid.cards {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1.5em;
  gap: 1.5em;
}
#homepage .page-body .grid.benefits {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 3em;
  gap: 3em;
}

#homepage .page-body .first-section {
  padding-top: 3em;
  padding-bottom: 3em;
  background: linear-gradient(180.09deg, #f5f5f5 2.25%, #ffffff 151.18%);
}
#homepage .page-body .first-section .headline,
#homepage .page-body .first-section .method-text {
  text-align: center;
}

#homepage .page-body .second-section {
  padding-top: 3em;
  padding-bottom: 3em;
}
#homepage .page-body .second-section .headline {
  text-align: center;
}

@media (min-width: 750px) {
  #homepage .page-body .grid.benefits {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1em;
    gap: 1em;
  }
}

@media (min-width: 1000px) {
  #homepage .page-body .third-section {
    padding: 0 3em;
  }
}

#homepage .page-body .fourth-section {
  padding-top: 1.5em;
  padding-bottom: 3.5em;
}

