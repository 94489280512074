#hompage {
  overflow-x: hidden !important;
}
#homepage .headline {
  text-align: center;
}
/* #homepage .page-body h1,
#homepage .page-body p {
  text-align: center;
} */
#homepage .page-body .method-text {
  color: var(--primary-dark-text);
  max-width: 700px;
  margin: auto;
}
#homepage .page-body .grid {
  margin-top: 3em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3em;
}
#homepage .page-body .grid.cards {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5em;
}
#homepage .page-body .grid.benefits {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3em;
}

#homepage .page-body .first-section {
  padding-top: 3em;
  padding-bottom: 3em;
  background: linear-gradient(180.09deg, #f5f5f5 2.25%, #ffffff 151.18%);
}
#homepage .page-body .first-section .headline,
#homepage .page-body .first-section .method-text {
  text-align: center;
}

#homepage .page-body .second-section {
  padding-top: 3em;
  padding-bottom: 3em;
}
#homepage .page-body .second-section .headline {
  text-align: center;
}

@media (min-width: 750px) {
  #homepage .page-body .grid.benefits {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1em;
  }
}

@media (min-width: 1000px) {
  #homepage .page-body .third-section {
    padding: 0 3em;
  }
}

#homepage .page-body .fourth-section {
  padding-top: 1.5em;
  padding-bottom: 3.5em;
}
