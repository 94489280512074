#homepage .page-header {
  /* min-height: 100vh; */
  /* width: 100%; */
  background: var(--primary-background);
  padding: 1rem 0;
  overflow: hidden;
}

#homepage .page-header .headline {
  color: var(--primary-light-text);
  text-align: center;
  line-height: 3.13rem;
  font-size: 2.66rem;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 3.5rem;
}
#homepage .page-header .headline > span {
  color: var(--primary-color);
}

#homepage .page-header .about {
  color: var(--secondary-light-text);
  text-align: center;
  max-width: 300px;
  margin: 2rem auto;
}
#homepage .page-header .header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
}
#homepage .page-header .header-content p {
  text-align: center;
}
#homepage .page-header .header-content img {
  width: 200px;
  max-width: 50%;
  margin-top: 2.5em;
  box-shadow: 2em 1em 3em rgba(0, 0, 0, 0.8), 4em 2em 5em rgba(0, 0, 0, 0.6),
    6em 3em 3em rgba(0, 0, 0, 0.4), 8em 4em 3em rgba(0, 0, 0, 0.3);
  border-radius: 2em;
  margin-bottom: -70px;
}
#homepage .page-header .textfield-button > .button {
  margin-top: 1.75em;
}

@media (min-width: 750px) {
  #homepage .page-header {
    padding-bottom: 5.5em;
  }
  #homepage .page-header .header-content {
    margin-top: 8em;
    max-width: inherit;
  }
  #homepage .page-header .header-content > div {
    max-width: 35em;
  }
  #homepage .page-header .header-content {
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
  }
  #homepage .page-header .headline .word-break {
    display: none;
  }
  #homepage .page-header .header-content img {
    margin-right: 1em;
    margin-left: 2em;
    margin-bottom: 0;
  }
  #homepage .page-header .headline {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }
  #homepage .page-header .about {
    text-align: left;
    max-width: 80%;
    margin: 2rem 0;
  }
  #homepage .page-header .header-content p {
    text-align: left;
  }

  #homepage .page-header .textfield-button {
    display: flex;
  }
  #homepage .page-header .textfield-button > .button {
    margin-top: 0;
    margin-left: 1em;
    flex-shrink: 1;
    width: auto !important;
  }
  #homepage .page-header .textfield-button > .textfield {
    flex-grow: 1;
  }
  #homepage .page-header .toolbar > .logo > h1 {
    display: block;
  }
}
@media (min-width: 960px) {
  #homepage .page-header .header-content img {
    margin-right: 5em;
    margin-left: 2em;
    margin-bottom: 0;
  }
}
