.offer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.offer > p,
.offer > h4 {
  text-align: center;
}
.offer > h3 {
  font-weight: 400;
  color: var(--secondary-dark-text);
}
.offer > p {
  color: var(--primary-dark-text);
  max-width: 80%;
  margin: 0 auto 1.5em;
}
.offer:first-child > img {
  max-width: 160px;
}
.offer > img {
  max-width: 120px;
  margin-top: auto;
}
