.card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* gap: 1em; */
}
.card.even{
  flex-direction: row-reverse;
}
.card > div{
  display: inline-flex;
  /* background-color: teal; */
  flex-grow: 1;
}
.card > div > div{
  display: inline-flex;
  flex-direction: column;
  margin-left: auto
}
.card.even > div > div{
  margin-right: auto;
  margin-left: 0;
}
.card p,
.card h3 {
  text-align: center;
  margin-top: -0.4em;
}
.card h3 {
  font-weight: 400;
  color: var(--secondary-dark-text);
}
.card p {
  color: var(--primary-dark-text);
  max-width: 80%;
  margin: -0.4em auto 0;
}
.card > img {
  max-width: 100px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(61, 48, 48, 0.2);
}
@media (min-width:750px) {
  .card {
    padding-right: 1em;
  }
  .card.even{
    padding-left: 1em;
  }
  .card > img {
    max-width: 150px;
    margin: auto;
  }
}
