#approval-dialog {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  z-index: -20;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100% + 2px);
}
#approval-dialog>* {
  overflow-y: auto !important;
}
#approval-dialog.show {
  z-index: 20;
  visibility: visible;
}
#approval-dialog > #container {
  margin: auto;
  width: 100%;
  max-width: 850px;
  transform: scale(0.2);
  transition: transform 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden !important;
  background-color: white;
  height: 100%;
  position: relative;
}
#approval-dialog .contents {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  overflow-y: auto;
}
#approval-dialog.show > #container {
  transform: scale(1);
}
#approval-dialog .forms {
  /* overflow-y: auto; */
  padding-top: 0 !important;
}
#approval-dialog .forms > div:not(:first-child) {
  max-width: 500px;
  margin: 0 auto;
}
#approval-dialog .forms > .close {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  padding-top: 1em;
  padding-bottom: 1em;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(1px);
}
#approval-dialog .forms .buttons > *:first-child {
  margin-bottom: 1em;
}
#approval-dialog .mobile-nav > div {
  max-width: 500px;
  margin: 0 auto;
}
#approval-dialog .forms > .close > img {
  width: 16px;
}
#approval-dialog .contents > div {
  padding: 1.5em;
}
#approval-dialog .contents > div:not(:first-child) {
  flex-grow: 1;
  padding-bottom: 2em;
}
#approval-dialog .contents > div:first-child {
  background: var(--primary-background);
}
#approval-dialog .tab-nav {
  display: none;
}
#approval-dialog .congrats > .logo > img {
  width: 1.8em;
}
#approval-dialog .congrats > .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}
#approval-dialog .congrats {
  animation: bounce-in 0.2s cubic-bezier(0.17, 0.67, 0.94, 1.21);
}
/* #approval-dialog .forms>div:not(:first-child) {
  animation: slide-left 0.2s ease;
} */
#approval-dialog .step-3 {
  animation: slide-left 0.2s ease;
}
#approval-dialog .step-2.is-forward {
  animation: slide-middle-left 0.2s ease;
}
#approval-dialog .step-2.is-backward {
  animation: slide-middle-right 0.2s ease;
}
#approval-dialog .step-1 {
  animation: slide-right 0.2s ease;
}

#approval-dialog .url-body {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

#approval-dialog .url-body > input {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 25px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid lightgray;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none !important;
}

#approval-dialog .url-body > button {
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #393939;
  padding: 0 20px;
  cursor: pointer;
  color: white;
}

#approval-dialog .referral-body {
  animation: bounce-out 0.2s cubic-bezier(0.17, 0.67, 0.94, 1.21);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  position: relative;
}
#approval-dialog .referral-body .ref-close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  max-width: 24px;
}
#approval-dialog .referral-body .links {
  padding: 30px;
  text-align: center;
}
#approval-dialog .referral-body .links > p {
  color: var(--primary-dark-text);
}
#approval-dialog .referral-body .links > h3 {
  margin-bottom: 0;
}
#approval-dialog .referral-body .rewards-container {
  background: radial-gradient(
    103.71% 76.48% at 50% 102.09%,
    #5c5c5c 0%,
    #282828 100%
  );
  padding: 30px;
  padding-top: 0;
  color: white;
}

#approval-dialog .rewards .reward {
  font-size: 0.9em;
  overflow-y: hidden;
  position: relative;
}
#approval-dialog .rewards .reward > .sm {
  padding-top: 25px;
  display: flex;
  gap: 20px;
  align-items: center;
}
#approval-dialog .rewards .reward > .sm > .info {
  margin: 0;
  max-width: 130px
}
#approval-dialog .rewards .reward > .bg {
  display: none;
}
#approval-dialog .rewards .reward .ref-count {
  text-align: center;
  color: black;
  background-color: white;
  border-radius: 50%;
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
#approval-dialog .rewards .reward .ref-count > div {
  font-weight: bold;
  margin-top: 3px;
}
#approval-dialog .rewards .reward > .sm::after {
  content: '';
  width: 3px;
  position: absolute;
  left: 18.5px;
  background-color: white;
  bottom: 0;
  height: 100%;
}
#approval-dialog .rewards .reward:last-child > .sm::after {
  bottom: 50%;
}
#approval-dialog .rewards .reward .ref-count > span {
  font-size: 10px;
  margin-top: -3px;
  display: block;
}
#approval-dialog .rewards .reward img {
  max-width: 90px;
}
#approval-dialog .referral-body .girl-img {
  display: none;
}
.copy-url-parent {
  display: flex;
  margin-top: 1em;
}
.copy-url-parent > div {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}
.copy-url {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 750px) {
  #approval-dialog .forms > .close > img {
    position: relative;
    top: 0;
    right: 0;
  }
  #approval-dialog {
    padding: 2em;
    backdrop-filter: blur(90px);
  }
  #approval-dialog > #container {
    overflow-y: hidden;
    max-height: 90%;
    border-radius: 10px;
  }
  #approval-dialog > #container.registered {
    overflow-y: auto;
  }
  #approval-dialog .contents {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  #approval-dialog .mobile-nav {
    display: none;
  }
  #approval-dialog .tab-nav {
    display: block;
  }
  #approval-dialog .contents > div {
    padding: 2.2em;
  }
  #approval-dialog .contents > .navigation {
    padding: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  #approval-dialog .contents > .forms {
    padding-top: 1em;
    overflow-x: hidden !important;
  }
  #approval-dialog .contents > .navigation > .tab-nav .info {
    padding: 1em 1.5em;
  }
  #approval-dialog
    .contents
    > .navigation
    > .tab-nav
    .info
    > p:not(:first-child) {
    margin: 2em 0;
  }
  #approval-dialog .forms .buttons {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 2em;
    gap: 1.5em;
    justify-content: center;
  }
  #approval-dialog .referral-body .girl-img {
    display: block;
    max-width: 200px;
    position: absolute;
    top: 0;
    right: 0;
  }
  #approval-dialog .forms .buttons > *:first-child {
    margin-bottom: 0;
  }
  #approval-dialog .rewards .reward > .sm {
    display: none;
  }
  #approval-dialog .rewards .reward > .bg {
    display: block;
    /* padding-left: 40px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  #approval-dialog .referral-body .rewards-container {
    padding-top: 30px;
    padding-left: 0;
    background: radial-gradient(
      73.99% 173.2% at 87.14% 15.59%,
      #5c5c5c 0%,
      #282828 100%
    );
  }
  #approval-dialog .rewards {
    display: flex;
  }
  #approval-dialog .rewards .reward {
    flex-grow: 1;
  }
  #approval-dialog .rewards .reward > .bg > .img-container {
    height: 100px;
    width: 100px;
    display: flex;
    margin-bottom: 20px;
  }
  #approval-dialog .rewards .reward > .bg > .img-container > img {
    width: 100px;
    max-width: 100%;
    margin: auto;
    transform: rotate(90deg);
  }
  #approval-dialog .rewards .reward > .bg > .ref-count {
    margin-bottom: 20px;
    position: relative;
    overflow-x: visible;
  }
  #approval-dialog .rewards .reward > .bg:after {
    content: '';
    height: 3px;
    background-color: white;
    position: absolute;
    left: 0;
    top: 139px;
    width: 100%;
  }
  #approval-dialog .rewards .reward:last-child > .bg:after {
    width: 50%;
  }
  #approval-dialog .referral-body .links {
    /* padding-bottom: 0px; */
  }
  #approval-dialog .rewards .reward > .bg > .info {
    text-align: center;
    max-width: 150px;
  }
  #approval-dialog .referral-body .links {
    text-align: start;
  }
  #approval-dialog .referral-body .links > h3 {
    font-size: 200%;
    margin-bottom: 0;
  }
  #approval-dialog .referral-body .links > p {
    max-width: 450px;
  }
  .copy-url {
    flex-direction: row;
    gap: 20px;
  }
  .copy-url-parent > div {
    max-width: 100%;
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-middle-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-middle-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.social-icon + .social-icon {
  margin-left: 0.8em;
  margin-top: 0.8em;
}

/* loader */

.loader {
  text-align: center;
}
.loader span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin: 50px auto;
  background: black;
  border-radius: 50px;
  -webkit-animation: loader 0.9s infinite alternate;
  -moz-animation: loader 0.9s infinite alternate;
}
.loader span:nth-of-type(2) {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
}
.loader span:nth-of-type(3) {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
}
@-webkit-keyframes loader {
  0% {
    width: 10px;
    height: 10px;
    opacity: 0.9;
    -webkit-transform: translateY(0);
  }
  100% {
    width: 24px;
    height: 24px;
    opacity: 0.1;
    -webkit-transform: translateY(-21px);
  }
}
@-moz-keyframes loader {
  0% {
    width: 10px;
    height: 10px;
    opacity: 0.9;
    -moz-transform: translateY(0);
  }
  100% {
    width: 24px;
    height: 24px;
    opacity: 0.1;
    -moz-transform: translateY(-21px);
  }
}
