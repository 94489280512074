.target {
  background: var(--primary-background);
  padding-top: 1.5em;
  /* padding-bottom: 2em */
  max-width: 1000px;
  margin: 2em auto 0;
}
.target .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.target .content .title,
.target .content .info {
  text-align: center;
}
.targets .target:nth-child(3) .content img{
  width: 100%;
  max-width: 289px;
}
.target img {
  margin-top: 2.5em;
  max-width: 100%;
  /* margin-bottom: -30px; */
}
.target .info {
  color: var(--secondary-light-text);
}
.target .title {
  color: var(--primary-light-text);
  /* font-size: 3.33em; */
  font-weight: normal;
}

@media (min-width: 750px) {
  .target {
    overflow: hidden;
  }
  .target .content {
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
  }
  .target .content.even {
    flex-direction: row-reverse;
    align-items: start;
    justify-content: space-between;
  }
  .target .content .title,
  .target .content .info {
    text-align: start;
  }
  .target .content .info {
    max-width: 35em;
    margin-bottom: 1.5em;
  }
  .target .content img {
    /* margin-top: -3.8em; */
    margin-top: -9%;
    margin-right: -7.3em;
    margin-left: 2em;
    width: 30em;
  }
  .target .content.even img {
    margin-top: -2em;
    margin-left: -4em;
    margin-right: 2em;
    width: 30em;
  }
  .targets .target:nth-child(3) .content img{
    margin-top: -6em;
    margin-left: 2em;
    margin-right: -9em;
    max-width: 22em;
  }
}
@media (min-width: 800px){
  .targets .target:nth-child(3) .content img{
    margin-top: -6em;
    margin-left: 2em;
    margin-right: -9em;
    max-width: 25em;
  }
}
@media (min-width: 1000px) {
  .targets .target:nth-child(3) .content img{
    margin-top: -6em;
    margin-left: 2em;
    margin-right: -9em;
    max-width: 27em;
  }
  .target {
    border-radius: 10px;
    margin: 4em auto 0;
  }
}
