#toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#toolbar .logo > h1 {
  display: none;
}
#toolbar .logo {
  color: var(--primary-light-text);
  display: flex;
  align-items: center;
}
#toolbar .logo.dark {
  color: #000;
}
#toolbar > div {
  display: flex;
  align-items: center;
}
#toolbar > div > *:not(:first-child) {
  margin-left: 1rem;
}

@media (min-width: 750px) {
  #toolbar .logo > h1 {
    display: block;
  }
}
