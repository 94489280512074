#homepage .page-footer {
  background: linear-gradient(111.27deg, #404040 4.89%, #282828 75.95%);
  position: relative;
  overflow: hidden;
  padding-top: 1em;
  padding-bottom: 2.5em;
}
#homepage .page-footer > div > .content {
  max-width: 500px;
  margin: 0 auto;
}
#homepage .page-footer .logo {
  display: none;
}
#homepage .page-footer > div > img {
  display: none;
}
#homepage .page-footer .content p {
  text-align: center;
  margin-bottom: 2em;
}
#homepage .page-footer .social-links {
  display: flex;
  justify-content: center;
  align-items: center;
}
#homepage .page-footer .social-links > * {
  margin: 0 0.5em !important;
}
#homepage .page-footer .copyright-social {
  margin-top: 2em;
}
#homepage .page-footer .copyright {
  margin: 1em 0;
  font-size: 0.9em;
}
#homepage .page-footer .textfield-button > .button {
  margin-top: 1.75em;
}
#homepage .page-footer .privacy-terms{
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
}

@media (min-width: 750px) {
  #homepage .page-footer {
    background: radial-gradient(
      100% 255.17% at 0% 22.13%,
      #282828 0%,
      #404040 58.79%,
      rgba(64, 64, 64, 0.6) 62.97%,
      rgba(64, 64, 64, 0.4) 67.11%,
      rgba(64, 64, 64, 0.2) 71.13%,
      rgba(64, 64, 64, 0) 75.31%
    );
    position: relative;
    overflow: hidden;
    padding-top: 1.5em;
    padding-bottom: 3em;
  }
  #homepage .page-footer > div > .content {
    margin: 0;
  }
  #homepage .page-footer > div > img {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    /* position: relative; */
    width: 50%;
    z-index: -1;
    height: 100%;
    object-fit: cover;
  }
  #homepage .page-footer > div > .content p {
    text-align: start;
  }
  #homepage .page-footer > div > .content .headline {
    text-align: start;
  }
  #homepage .page-footer > div > .content .textfield-button {
    display: flex;
  }
  #homepage .page-footer > div > .content .textfield-button .button {
    margin-top: 0;
    margin-left: 1em;
    width: auto !important;
  }
  #homepage .page-footer .textfield-button > .textfield {
    flex-grow: 1;
  }
  #homepage .page-footer .copyright-social {
    display: flex;
    margin-top: 3em;
  }
  #homepage .page-footer .copyright-social > .social-links {
    margin-left: 1em;
  }
  #homepage .page-footer .logo {
    display: flex;
    align-items: center;
    color: var(--primary-light-text);
  }
  #homepage .page-footer .logo > h1 {
    margin-left: 0.3em;
  }
  #homepage .page-footer .privacy-terms{
    justify-content: flex-start;
  }
}
