#button {
  border: none;
  padding: 0.5em 2em;
  position: relative;
  cursor: pointer;
  font-size: 0.9rem
}
#button:hover::after{
    content: '';
    position:absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    background: linear-gradient(35deg,rgba(255,255,255,0.3), rgba(255,255,255,0));
}
#button .children {
  white-space: nowrap !important;
}
#button.loading .loader {
  display: block;
}
#button.loading .children {
  opacity: 0;
}

#button.disabled {
  background-color: rgba(199, 199, 199, 0.47) !important;
  color: rgba(79, 78, 78, 0.78) !important;
  cursor: default;
  box-shadow: none !important
}

#button .loader {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 50%;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid white;
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  display: none;
  animation: rotate 0.6s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

#action-button {
  border: none;
  border-radius: 5px;
  padding: 4px 6px;
}
