#snackbar {
  position: fixed;
  height: auto;
  width: 100%;
  overflow: visible;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  top: 0;
  z-index: -1;
  opacity: 1;
  transition: none 1s ease;
  transition-property: z-index,opacity,transform,visibility;
  padding: 0 1em;
}
#snackbar.show {
  z-index: 800;
  opacity: 1;
}
#snackbar > div {
  max-width: 400px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  color: white;
  visibility: hidden;
  transition: none 1s ease;
  transition-property: z-index,opacity,transform,visibility;
  transform: translateY(-7em);
  padding: 1em 2em;
  border-radius: 4px;
}
#snackbar.error > div{
  background-color: rgba(250, 120, 121, 1);
}
#snackbar.success > div{
  background-color: rgba(38, 170, 118, 1);
}
#snackbar.info > div{
  background-color: #5294F7;
}
#snackbar.show > div {
  visibility: visible;
  transform: translateY(2em);
}
