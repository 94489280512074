@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

body {
  box-sizing: border-box;
  font-family: 'Roboto Condensed', sans-serif !important;
  overflow-x: hidden !important;
}
body *,
body * > * {
  box-sizing: border-box !important;
  font-family: 'Roboto Condensed', sans-serif !important;
}

:root {
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;

  --primary-color: #18be91;
  --primary-background: radial-gradient(
    75.56% 191.34% at 5.6% 5.41%,
    #404040 0%,
    #282828 100%
  );
  --primary-dark-text: rgb(64, 64, 64);
  --secondary-dark-text: rgba(18, 18, 18, 1);
  --primary-light-text: #fff;
  --secondary-light-text: rgba(179, 179, 179, 1);
}
a{
  color: var(--primary-color)
}
.input {
  font-size: 0.9em;
}
.primary-color {
  color: var(--primary-color);
}
.secondary-light-color {
  color: var(--secondary-light-text);
}
.secondary-dark-color {
  color: var(--secondary-dark-text);
}
.primary-light-color {
  color: var(--primary-light-text);
}
.primary-dark-color {
  color: var(--primary-dark-text);
}
.general-body {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  display: flex;
}
.general-body > * {
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.general-shadow {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}
.center-text {
  text-align: center;
}

.weight-bold {
  font-weight: 600;
}
.weight-normal {
  font-weight: 400;
}
.font-size-sm {
  font-size: 0.9em;
}

.mt-1 {
  margin-top: 0.25em;
}
.mt-2 {
  margin-top: 0.5em;
}
.mt-3 {
  margin-top: 0.75em;
}
.mt-4 {
  margin-top: 1em;
}
.mt-5 {
  margin-top: 1.25em;
}
.mt-6 {
  margin-top: 1.5em;
}
.mt-7 {
  margin-top: 1.75em;
}
.mt-8 {
  margin-top: 2em;
}
.pointer {
  cursor: pointer;
}
.inline-block {
  display: inline-block;
}
@media (min-width: 750px) {
  :root {
    font-size: 18px;
  }
  .general-body {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
}
