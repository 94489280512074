input {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  input.outlined {
    border: 1px solid;
    border-radius: 5px;
  }
  input {
    border: none;
    /* padding: 20px 62px 20px 25px; */
    border-radius: 5px;
    outline: none;
    font-size: 0.9rem;
  }
  